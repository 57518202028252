// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-eleusis-js": () => import("./../../../src/pages/eleusis.js" /* webpackChunkName: "component---src-pages-eleusis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-dla-js": () => import("./../../../src/pages/portfolio/dla.js" /* webpackChunkName: "component---src-pages-portfolio-dla-js" */),
  "component---src-pages-portfolio-main-js": () => import("./../../../src/pages/portfolio/main.js" /* webpackChunkName: "component---src-pages-portfolio-main-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

